import { Link } from 'react-router-dom';

const AccountRemoval = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#0a1429] text-white p-4">
      <div className="max-w-3xl w-full">
        <div className="mb-8">
          <Link to="/" className="text-[#00e1ff] hover:text-[#00b3cc] transition-colors flex items-center gap-2">
            <span>←</span> Back to Home
          </Link>
        </div>

        <h1 className="text-4xl font-bold mb-8">Account Removal Request</h1>

        <div className="space-y-8">
          <p>You can delete your account in the following way:</p>

          <div>
            <h2 className="text-2xl font-bold mb-4">1. Via Email Request</h2>
            <p className="mb-2">Alternatively, you can request account deletion via email:</p>
            <ul className="list-none space-y-2">
              <li>Send an email to <a href="mailto:contato@luisfebro.com" className="text-[#00e1ff] hover:text-[#00b3cc] transition-colors">contato@luisfebro.com</a></li>
              <li>Include your account email in the request</li>
              <li>Specify "Account Removal Request" in the subject</li>
            </ul>
          </div>

          <p className="mt-6">We will process your request within 30 days and notify you once completed.</p>
        </div>
      </div>
    </div>
  );
};

export default AccountRemoval;


/*

<div>
            <h2 className="text-2xl font-bold mb-4">1. Through the App</h2>
            <p className="mb-2">The easiest way to delete your account is directly through the app:</p>
            <ul className="list-none space-y-2">
              <li>Open the app</li>
              <li>Go to More Settings</li>
              <li>Tap on "Delete Account"</li>
            </ul>
          </div>

*/