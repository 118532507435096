import { Routes, Route } from 'react-router-dom';
import Home from '@/pages/Home';
import About from '@/pages/About';
import Portfolio from '@/pages/Portfolio';
import Contact from '@/pages/Contact';
import Blog from '@/pages/Blog';
import ClientAccess from '@/pages/ClientAccess';
import AccountRemoval from '@/pages/AccountRemoval';

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/login" element={<ClientAccess />} />
      <Route path="/scribescan/account-removal" element={<AccountRemoval />} />
    </Routes>
  );
};

export default Routers;